.drone-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(176, 255, 200);
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.drone-container > h1 {
  background-position: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #121212;
  font-size: 100px;
}

.drone-container > h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  background-position: center;
  color: #121212;
  font-size: 60px;
}

.drone-container > p {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 80vw;
  color: rgb(92, 92, 92);
  font-size: 24px;
  justify-content: left;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.drone-container > p > ul {
  padding-left: 25px;
}

@media screen and (max-width: 960px) {

  .drone-container > h1 {
    font-size: 70px;
  }

  .drone-container > h2 {
    font-size: 45px;
  }

}

@media screen and (max-width: 768px) {

  .drone-container > h1 {
    font-size: 50px;
  }

  .drone-container > h2 {
    font-size: 35px;
  }

  .drone-container > p {
    font-size: 18px;
  }

}

.nature_cards__header {
  margin-bottom: 24px;
}

.nature_cards__items {
  display: flex;
  margin-bottom: 24px;
  grid-template-columns: repeat(2, auto);
  flex-wrap: wrap;
  flex-direction: row;
}

.video_cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  background: rgb(255, 255, 255);
}

.video_cards__item__link {
  display: flex;
  flex-flow: column;
  width: 35vw;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.797);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.video_cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.video_cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.video_cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.video_cards__item__img:hover {
  transform: scale(1.1);
}

.video_cards__item__info {
  padding: 20px 30px 30px;
}

.video_cards__item__text {
  color: #252e48;
  font-size: 24px;
  line-height: 24px;
}

@media only screen and (min-width: 1024px) {
  .video_cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .video_cards__item {
    margin-bottom: 2rem;
  }
  .nature_cards__items {
    flex-direction: column;
  }

  .video_cards__item__link {
    width: 75vw;
  }

}