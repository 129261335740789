.contact-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(176, 255, 200);
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.contact-container > h1 {
  background-position: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #121212;
  font-size: 100px;
}

.contact-container > h3 {
  margin-top: 5px;
  margin-bottom: 20px;
  background-position: left;
  justify-content: left;
  color: #121212;
  font-size: 40px;
}

.contact-container > h2 {
  margin-top: 5px;
  margin-bottom: 20px;
  background-position: left;
  justify-content: left;
  color: #121212;
  font-size: 40px;
}

@media screen and (max-width: 960px) {

  .contact-container > h1 {
    font-size: 70px;
  }

  .contact-container > h3 {
    font-size: 30px;
  }

  .contact-container > h2 {
    font-size: 30px;
  }

}

@media screen and (max-width: 768px) {

  .contact-container > h1 {
    font-size: 50px;
  }

  .contact-container > h3 {
    font-size: 28px;
  }

  .contact-container > h2 {
    font-size: 18px;
  }

}
