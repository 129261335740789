  .about-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(176, 255, 200);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .about-container > h1 {
    background-position: center;
    color: #121212;
    font-size: 100px;
  }

  .about-profile-photo {
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 30px;
    width: 30vw;
    justify-content: center;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.783);
    border-radius: 15px;
  }

  .about-container > h2 {
    margin-top: 10px;
    margin-bottom: 20px;
    background-position: center;
    color: #121212;
    font-size: 60px;
  }

  .about-container > h3 {
    margin-top: 5px;
    margin-bottom: 20px;
    background-position: center;
    color: #121212;
    font-size: 40px;
  }
  
  .about-container > p {
    margin-bottom: 20px;
    width: 80vw;
    color: rgb(92, 92, 92);
    font-size: 24px;
    justify-content: left;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  @media screen and (max-width: 960px) {

    .about-container > h1 {
      font-size: 70px;
    }

    .about-container > h2 {
      font-size: 45px;
    }

    .about-container > h3 {
      font-size: 30px;
    }

  }
  
  @media screen and (max-width: 768px) {

    .about-container > h1 {
      font-size: 50px;
    }
  
    .about-container > h2 {
      font-size: 40px;
    }

    .about-container > h3 {
      font-size: 28px;
    }

    .about-container > p {
      font-size: 18px;
    }

    .about-profile-photo {
      width: 60vw;
    }
  
  }


  