.portraits-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(176, 255, 200);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .portraits-container > h1 {
    color: #000;
    font-size: 100px;
  }

  .portraits-container > h2 {
    margin-top: 10px;
    margin-bottom: 20px;
    background-position: center;
    color: #121212;
    font-size: 60px;
  }

  .gallery-intro-photo {
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 30px;
    width: 70vw;
    justify-content: center;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.783);
    border-radius: 15px;
  }  

  .gallery-highlight-photo{
    width: 70vw;
  }

  .portrait-row {
    flex-flow: column;
  }

  .portrait-row > a > img {
    width: 35vw;
    margin-left: 10px;
    margin-right: 10px;
  }

  .gallery-photo {
    margin-top: 15px;
    margin-bottom: 30px;
    justify-content: center;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.783);
    border-radius: 15px;
  }
  
  @media screen and (max-width: 960px) {
    .portraits-container > h1 {
      font-size: 70px;
    }

    .portraits-container > h2 {
      font-size: 45px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .portraits-container > h1 {
      font-size: 50px;
    }

    .portraits-container > h2 {
      font-size: 35px;
    }

    .portrait-row {
      display: flex;
    }

    .portrait-row > a > img {
      width: 70vw;
    }
  
  }
  